import {Controller} from "@hotwired/stimulus";
import IframeCloser from '../objects/iframe_closer';

export default class extends Controller {

  LOG_SIGNATURE = '[close_controller]';

  connect(){
   //console.log(this.LOG_SIGNATURE, 'connect');
  }

  onClose(){
    const closer = new IframeCloser();
    closer.closeIframe();
    //console.log(this.LOG_SIGNATURE, 'onClose button clicked', this.element.nearest());
  }

}
