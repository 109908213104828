import { Controller } from '@hotwired/stimulus';

import 'izimodal/js/iziModal.min';
import 'izimodal/css/iziModal.min.css';

//ONLY RELATIVE PATH IN  data-modal-iframeurl
//!!!ABSOLUTE IS FORBIDDEN
export default class extends Controller {
  connect() {

    this.$ = itb.$;

    let $link = this.$(this.element);
//sdfdfgg
    let id = $link.attr('data-modal-id');

    let _this = this;

    if (this.isInIframe()) {

      this.element.addEventListener('click', (event) => {

        event.preventDefault();

        //console.log('itb post message', itb);

        _this.injectModals();


        let config = this.getModalConfig($link);
        config.autoOpen = true;

        window.parent.postMessage({
          action: 'accessItb', data: {
            status: 'ok',
            actions: [{
              type: 'modal',
              config: config
            }]

          }
        }, '*');


      });

    } else {

      this.element.addEventListener('click', (event) => {

        _this.injectModals();

        event.preventDefault();

        const config = this.getModalConfig($link);
       //console.log('config2 - ',config);

        this.createModal(config)

        //console.log('$link',$link);

        event.preventDefault();

        ////console.log('Link clicked', id+'_modal');

        this.$('#' + id).iziModal('open');

      });
    }
  }

  injectModals() {

    let $currentElement = this.$(this.element);

    const $allGroupElements = this.$(`[data-modal-group='${$currentElement.attr('data-modal-group')}']`);
    const currentIndex = $allGroupElements.index($currentElement);


      this.$.each($allGroupElements,(index, el)=>{
       const config = this.getModalConfig(this.$(el))
      //console.log('config - ',config)
       this.createModal(config);
    });

  }

  isInIframe() {
    return window !== window.parent;
  }

  createModal(config) {
    //console.log('config modals - ',config.iframeURL);
    let id = config.id;

    if (this.isInIframe()) {

      window.parent.postMessage({
        action: 'accessItb', data: {
          status: 'ok',
          actions: [{
            type: 'modal',
            config: config
          }]

        }
      }, '*');

    }else{


      let modalId = id+'_modal';
      ////console.log('create modal id',id, modalId, config );

      let modalHtml = `
      <div id="${id}" class="iziModal"
        ${config.group ? `data-izimodal-group="${config.group}"` : ''}
      >
        <div class="iziModal-content">
          ${config.title ? `<h2>${config.title}</h2>` : ''}
          ${config.subtitle ? `<p>${config.subtitle}</p>` : ''}
        </div>
      </div>
    `;

        this.$('body').append(modalHtml);//console.log('`#${id}`',`#${id}`)
        this.$(`#${id}`).iziModal(config);

    }




  }

  getModalConfig($element) {
    let iframeUrl = '';
    if (this.getBackendUrl()){
        iframeUrl = this.getBackendUrl()+$element.attr('data-modal-iframeurl');
    }else{
        iframeUrl = $element.attr('data-modal-iframeurl');
    }
   //console.log('[link_controller],getModalConfig iframeurl , $element.attr(\'data-modal-id\' ',iframeUrl, $element.attr('data-modal-id'),this.id);
    return {
      id: $element.attr('data-modal-id') || this.id,
      title: $element.attr('data-modal-title') || '',
      subtitle: $element.attr('data-modal-subtitle') || '',
      width: $element.attr('data-modal-width') || '700px',
      iframe: true,
      iframeHeight: $element.attr('data-modal-height') || '500px',
      iframeURL: iframeUrl,
      group: $element.attr('data-modal-group') || 'review',
      transitionIn: 'fadeInLeft',
      transitionOut: 'fadeOutRight',
      theme: $element.attr('data-modal-theme') || '',
      navigateCaption: true,
      navigateArrows: 'closeScreenEdge',
      arrowKeys: true,
      // onOpening: this.beforeOpen.bind(this),

    };
  }

  getBackendUrl(){
    if (typeof window.itbReviewsBackendUrl !== 'undefined'){
        return window.itbReviewsBackendUrl;
    }else{
       return false;
    }
  }

}
