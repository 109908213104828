import { Controller } from '@hotwired/stimulus';
import HandleBars from "handlebars";
import $ from 'jquery';

import Swiper from "swiper";
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

//import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './../../public/carousel/testimonial.scss';

import Handlebars from "handlebars";
//import 'bootstrap-star-rating/js/star-rating.min';
//import 'bootstrap-star-rating/css/star-rating.css';
//import 'bootstrap-star-rating/themes/krajee-svg/theme.css';
//import 'bootstrap-star-rating/themes/krajee-svg/theme.js';

export default class extends Controller {

  LOG_SIG = '[list_testimonial_controller]';

  static values = {
    reviews: Array,
  }

  connect() {
   if (this.getMetafields().reviews === undefined){
      //console.log(this.LOG_SIG," onconnect() returning cuz reviews metafields are empty");
       return;
   }
    this.showCarousel();
  //console.log('[list_testimonial] connect()');

    const handlebarsHTML = this.element.querySelector('#review_testimonial_item')
    if (handlebarsHTML){

     //console.log('swiper mode inline');
      this.loadHibernateTemplate(handlebarsHTML).then(()=>{
        //this.initCarousel();
        this.initSwipper();
      });


    }else{
      ///this.initCarousel();
      this.initSwipper();

    }

    window.Swiper = Swiper;

}


  initSwipper(){

   //console.log('swiper started');
   //console.log('[list_testimonial_controller] initSwiper() swiper init setup SlidersPerView 1')
    let container = this.element.querySelector('.swiper-container');
    let pagination = this.element.querySelector(".swiper-pagination");
    this.swiper = new Swiper(container, {

      slidesPerView: 1, // zmniejsz liczbę slajdów widocznych naraz
      slidesPerGroup: 1, // zmniejsz liczbę slajdów przewijanych naraz
      pagination: {
        el: pagination,
        dynamicBullets: true,
        clickable: true,
      },
      loop: true
    });

    window.swiperOb = this.swiper ;
//
    $(this.element.querySelector('.swiper-button-next')).click(()=>{
     //console.log(this.LOG_SIG,' next ');
      this.swiper.slideNext();
    })
    $(this.element.querySelector('.swiper-button-prev')).click(()=>{
      this.swiper.slidePrev();
    })

  }

  getReviews(name='carousel') {
    return this.getMetafields()['reviews'];
  }

  async loadHibernateTemplate(elem){

    const html = elem.innerHTML;
    const itb_widget = this.element.closest('[data-controller="itb-component"]')

    //console.log('swiper loadHibernateTemplate');
    //console.log('swiper handlebars');

    HandleBars.registerHelper('nthElement', function(array, index) {
      // Sprawdzamy, czy podana tablica istnieje i czy indeks jest w jej zakresie
      if (Array.isArray(array) && index < array.length && index >= 0) {
        return array[index];
      } else {
         return ''; // Zwraca pustą wartość, jeśli indeks jest poza zakresem lub tablica nie istnieje
      }
    });

    const renderFunc = HandleBars.compile(html);

    const data = {
        reviews: this.getReviews(),
        widget_url:itb_widget?.getAttribute('data-itb-component-widget-url') ?? 'error',
        product_handle:itb_widget?.getAttribute('data-itb-component-product-handle') ?? 'error',
        shop_name_h:itb_widget?.getAttribute('data-itb-component-data-shop-handle') ?? 'error',
    }

    //console.log('swiper handlebars data ',data);

    const result = renderFunc(data);
    this.element.querySelector('.swiper-wrapper').innerHTML = result;
   //console.log('swiper content created ');

  }

  showCarousel(){
    const carouselBody = document.getElementById('body_testimonials');

    // Ustawienie stylu 'display' na 'block'
    carouselBody.style.display = 'block';

    // Dodanie klasy 'animate__fadeIn'
    carouselBody.classList.add('animate__fadeIn');
  }

  getMetafields(){
      return {'reviews':this.reviewsValue};
  }

}
