import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["button", "text"]

  connect() {
    //console.log("SortOptions controller connected"); // Debug log to ensure controller is connected
  }

  select(event) {
    event.preventDefault();
    //console.log('sdfsfdsfd');
    const selectedValue = event.currentTarget.dataset.value;
    const selectedText = event.currentTarget.innerText;
    this.textTarget.innerText = selectedText; // Update only the text within the <span>

    // Update the hidden select element with the new value and trigger change event
    const sortBySelect = document.getElementById('sort-by');
    //console.log('event bubbles up',sortBySelect);
    sortBySelect.value = selectedValue;
    const eventChange = new Event('change');
    sortBySelect.dispatchEvent(eventChange);
  }
}
