import { Controller } from '@hotwired/stimulus';

import $ from 'jquery';
import HandleBars from 'handlebars'

import  Swiper from 'swiper';
import  { Navigation,Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
//console.log('Navigation');



//import 'bootstrap-star-rating/js/star-rating.min';
//import 'bootstrap-star-rating/css/star-rating.css';
//import 'bootstrap-star-rating/themes/krajee-svg/theme.css';
//import 'bootstrap-star-rating/themes/krajee-svg/theme.js';

import './../../public/carousel/carousel.scss';
import Handlebars from "handlebars";

export default class extends Controller {
//
  static values = {
    reviews: Array
  }

  LOG_SIGNATURE="[list_carousel_controller]"


  connect() {
    //console.log(this.LOG_SIGNATURE,' connect()',this.reviewsValue['reviews']);
    //console.log('this.reviewsValue f2a',this.getMetafields().reviews);
    if (this.getMetafields().reviews === undefined){
      //console.log("returning cuz reviews metafields are empty");
      return;
    }

    this.showCarouselBlock();

    const hibernate = this.element.querySelector('#review_carousel_item')
    //console.log(this.LOG_SIGNATURE,' connect() html',hibernate);
    if (hibernate){
      this.loadHibernateTemplate(hibernate);
      this.initCarousel();
    }
    this.initSwipper();
  }

  loadHibernateTemplate(elem){
     //console.log(this.LOG_SIGNATURE, ' loadHibernatTemplate() elem  ',elem);
     const html = elem.innerHTML;
     const itb_widget = this.element.closest('[data-controller="itb-component"]')

     HandleBars.registerHelper('nthElement', function(array, index) {
          // Sprawdzamy, czy podana tablica istnieje i czy indeks jest w jej zakresie
          if (Array.isArray(array) && index < array.length && index >= 0) {
            return array[index];
          } else {
            return ''; // Zwraca pustą wartość, jeśli indeks jest poza zakresem lub tablica nie istnieje
          }
     })

     const renderFunc = HandleBars.compile(html);

     let widget_url = '';

     let iframeUrl = '';

     const metafields = this.getMetafields();


     const data = {
        reviews: metafields?.reviews.map(e=>{
           //console.log(e.logo_path);
            return e;
        }) ?? [],
        widget_url:this.getBackendUrl() ?? 'error',
        product_handle:itb_widget?.getAttribute('data-itb-component-product-handle') ?? 'error',
        shop_name_h:itb_widget?.getAttribute('data-itb-component-data-shop-handle') ?? 'error',
        ratting_info:  metafields?.ratting_info ?? []
     }

     //console.log(this.LOG_SIGNATURE,' loadHibernatTemplate() data is ', data);
     const result = renderFunc(data);
     //console.log(this.LOG_SIGNATURE,' render result', result);
     this.element.querySelector('.swiper-wrapper').innerHTML = result;

  }



  initCarousel(){

    let items = document.querySelectorAll('.carousel .carousel-item');

    items.forEach((el) => {
      const minPerSlide = 5;
      let next = el.nextElementSibling;
      console.log()
      for (var i = 1; i < minPerSlide; i++) {
        if (!next) {
          next = items[0];
        }
        let cloneChild = next.cloneNode(true);
        el.appendChild(cloneChild.children[0]);
        next = next.nextElementSibling;
      }
    });

  }

  initSwipper(){
    //console.log(this.LOG_SIGNATURE,' initswiper ');
    this.swiper = new Swiper(this.element.querySelector('.swiper-container'), {
      slidesPerView: 1,
      spaceBetween: 10,
      loop: true,
      breakpoints: {
        576: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        992: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
    });

    //console.log(this.LOG_SIGNATURE,' swiper is ', this.swiper);
    $(this.element.querySelector('.swiper-button-next')).click(()=>{
      //console.log(this.LOG_SIGNATURE,' slide next',this.swiper)
      this.swiper.slideNext();
    })
    $(this.element.querySelector('.swiper-button-prev')).click(()=>{
      //console.log(this.LOG_SIGNATURE,' slide prev',this.swiper)
      this.swiper.slidePrev();
    })

  }

  getMetafields(){
      return {'reviews':this.reviewsValue};
  }

  getBackendUrl(){
       return window.itbReviewsBackendUrl;
   }

  showCarouselBlock(){
    const carouselBody = document.querySelector('#body_carousel .swiper-container');

// Ustawienie stylu 'display' na 'block'
    carouselBody.style.display = 'block';

// Dodanie klasy 'animate__fadeIn'
    carouselBody.classList.add('animate__fadeIn');
  }

}
