import { Controller } from '@hotwired/stimulus';

import AOS from 'aos';
import 'aos/dist/aos.css';

export default class extends Controller {
  static values = {
    rating: Number
  }

  connect() {

    const rating = this.ratingValue;
    const starsContainer = this.element;

    // Add the 'rating-stars' class to the container
    starsContainer.classList.add('rating-stars');
    // starsContainer.setAttribute('data-aos', 'fade-up');

    // Clear existing stars
    starsContainer.innerHTML = '';

    // Generate new stars
    for (let i = 1; i <= 5; i++) {
      const star = document.createElement('i');
      star.className = `fa${rating >= i ? 's' : 'r'} fa-star`;
      starsContainer.appendChild(star);
    }
  }
}
