import { Controller } from '@hotwired/stimulus';


import $ from 'jquery';
import 'animate.css';

import Masonry from 'masonry-layout';
import Isotope from 'isotope-layout';


//import 'bootstrap-star-rating/js/star-rating.min';
//import 'bootstrap-star-rating/css/star-rating.css';
//import 'bootstrap-star-rating/themes/krajee-svg/theme.css';
//import 'bootstrap-star-rating/themes/krajee-svg/theme.js';

import '../../public/infinity/infinity.scss';

// import  'bootstrap';
import Dropdown from 'bootstrap/js/dist/dropdown';

import '@popperjs/core';

import Handlebars from 'handlebars';

export default class extends Controller {
  LOG_SIG = '[list_infinity_controller]'

  static values = {
    reviews: Array,
    url: String
  }

  connect() {
    if (this.getMetafields().reviews === undefined){
        //console.log("returning cuz reviews metafields are empty");
        return;
    }
    //console.log(this.LOG_SIG,' connect() ')
     //console.log(this.LOG_SIG+'this.urlValue'+this.urlValue)
    this.initGrid();
    const reviews = this.getMetafields()?.reviews ?? [];
    this.loadItems(reviews);

  }

  handleItemClick(element){
   //console.log('clicked');
  }

  initGrid() {
    const grid = this.element.querySelector('.grid');
    this.iso = new Isotope(grid, {
      itemSelector: '.grid-item',
      masonry: {
        // columnWidth: 270,
      },
      getSortData: {
        date: '[data-date] parseInt',
        rating: '[data-rating] parseInt'
      }
    });

    window.iso = this.iso;

    $('#rating-filter').on('change', (event) => {
      this.loadReviews();
    });

    $('#language-filter').on('change', (event) => {
      this.loadReviews();
    });

    $('#sort-by').on('change', (event) => {
      this.loadReviews();
    });

    $('#load-more-reviews').on('click',  ()=> {
      const offset = $('.grid-item').length;
      this.loadReviews(offset);
    });
  }

  loadReviews(offset = 0) {
    let baseUrl = '';
    baseUrl = this.getBackendUrl()+this.urlValue;


    const ratingFilter = $('#rating-filter').val();
    const languageFilter = $('#language-filter').val();
    const sortBy = $('#sort-by').val();

    const params = new URLSearchParams();
    params.append('offset', offset);

    if (ratingFilter) {
      params.append('rating', ratingFilter);
    }
    if (languageFilter) {
      params.append('language', languageFilter);
    }
    if (sortBy) {
      params.append('sort_by', sortBy);
    }

    const url = `${baseUrl}?${params.toString()}`;

    itb.effects.loading('#load-more-reviews')
    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (offset === 0) {
          this.iso.remove($('.grid-item'));
        }
        this.loadItems(data);
      }).finally(()=>{

      itb.effects.removeLoading('#load-more-reviews')

    })
    ;
  }

  loadItems(data){

    const source = document.getElementById('review-template').innerHTML;
    const template = Handlebars.compile(source);
    const items = data.map(review => {
      review.date = new Date(review.created_at).getTime();
      review.formattedDate = this.formatDate(review.created_at);
      review.widget_url = this.getBackendUrl();
      ////console.log('ddddd',review);
      const html = template(review);
      const element = document.createElement('div');
      element.innerHTML = html;
      return element.firstElementChild;
    });


    this.itms = items;
   //console.log(this.LOG_SIG+' loaditems() items ',items.length);
    this.iso.insert(items);
    this.iso.layout();

    setTimeout(()=>{
      this.iso.layout();
      setTimeout(()=>{
        if (this.isInChildWindow()){
          itb.resizer.resizeParent();
        }
      },100)

    },600);

    this.showCarousel();

  }

  formatDate(dateString) {

    const date = new Date(dateString);
    const userLang = navigator.language || navigator.userLanguage;

    // Użyj języka przeglądarki, jeśli jest dostępny, w przeciwnym razie użyj 'en-GB'
    const locale = userLang ? userLang : 'en-GB';

    return date.toLocaleDateString(locale);
  }

  isInChildWindow(){
    window.self !== window.top
  }



  showCarousel(){
    //console.log('showcarousel');
    const carouselBody = document.querySelector('#body_infinity #infinity-container');

    // Ustawienie stylu 'display' na 'block'
    carouselBody.style.display = 'block';

    // Dodanie klasy 'animate__fadeIn'
    carouselBody.classList.add('animate__fadeIn');
  }

    getBackendUrl(){
        return window.itbReviewsBackendUrl;
    }

    getMetafields(){
        return {'reviews':this.reviewsValue};
    }

}
