import { Controller } from '@hotwired/stimulus';
import 'animate.css';

export default class extends Controller {
  static values = {
    ratting: Object
  }

    reviewDetailsCard;
    toggleIcon;

  connect() {
    //console.log('ratting bar start',this);
    const ratting = this.rattingValue;
    const reviewSummary = this.element.querySelector('.review-summary');
    this.reviewDetailsCard = this.element.querySelector('.review-details');
    this.toggleIcon = this.element.querySelector('.toggle-icon');
    // Update the review summary
    reviewSummary.querySelector('span').innerText = `(${ratting.total_votes} Reviews)`;

    // Clear existing review details
    const reviewDetailsList = this.reviewDetailsCard.querySelector('ul.list-group');
    reviewDetailsList.innerHTML = '';

    // Generate new review details
    const stats = ratting.stats;
    const ratingKeys = [5, 4, 3, 2, 1]; // Define the order of ratings

    ratingKeys.forEach(rating => {
      const count = stats[rating] || 0;
      const listItem = document.createElement('li');
      listItem.className = 'list-group-item';
      listItem.innerHTML = `
        <div data-controller="rating-stars" data-rating-stars-rating-value="${rating}"></div>
        <span class="float-right">(${count})</span>`;
      reviewDetailsList.appendChild(listItem);
    });

   //console.log('ratting bar start add event listener ');
    // Add click event listener to reviewSummary
    reviewSummary.addEventListener('click', (event) => {
      //console.log('ratting bar start click');
      event.stopPropagation();
      if (this.reviewDetailsCard.classList.contains('d-none')) {
        this.openDropDown();
      } else {
        this.closeDropDown();
      }

      this.registerOutsideClick('.review-details',(event)=>{
          this.closeDropDown();
      })

    });
  }

  registerOutsideClick(elem,cb){
      window.addEventListener('click', (event)=> {
          const modal  = this.element.querySelector(elem);
          let clickInside = modal.contains(event.target)
          if (!clickInside){
              cb(event);
          }
      });
  }

  closeDropDown(){
      this.reviewDetailsCard.classList.add('d-none');
      this.reviewDetailsCard.classList.remove('animate__animated', 'animate__fadeIn');
      this.toggleIcon.classList.remove('fa-chevron-up');
      this.toggleIcon.classList.add('fa-chevron-down');
  }

  openDropDown(){
      this.reviewDetailsCard.classList.remove('d-none');
      this.reviewDetailsCard.classList.add('animate__animated', 'animate__fadeIn');
      this.toggleIcon.classList.remove('fa-chevron-down');
      this.toggleIcon.classList.add('fa-chevron-up');
  }

}
