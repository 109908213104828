import {Controller} from "@hotwired/stimulus";
import Plyr from "plyr";
import '../css/presentation.css';
import Swiper from 'swiper';
import 'swiper/css';
import {Mousewheel, Navigation, Pagination} from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'plyr/dist/plyr.css';
import HandleBars from "handlebars";



Swiper.use([Navigation, Pagination, Mousewheel]);




export default class extends Controller {

    static values = {
        reviews: Array
    }

    LOG_SIGNATURE = "[presentation_controller]";

    videoHeight;
    videoWidth;
    id;
    reviews = [];
    currentVideoIndex= 0;
    itb;
    playerHandle;

    swiper = false;
    modal = false;
    sliderPlayingState = false;
    sliderVideos=false;


    connect() {
        super.connect();
        window.pres = this;
        this.videoHeight = 640;
        this.videoWidth  = 360;

        this.currentVideoIndex = 0;
        this.reviews = this.getReviews();

        this.id = $(this.element).attr('id');
        this.modalID = 'modal-'+this.id;

        //console.log'presentation start ', this.id);

        let _this = this;


        this.handlbars = HandleBars;
        this.init();
        this.playIconsVideo();
        this.itb = window.itb;
        this.showCarousel();

    }

    showCarousel(){
        const carouselBody = this.element;

// Ustawienie stylu 'display' na 'block'
        carouselBody.style.display = 'block';

// Dodanie klasy 'animate__fadeIn'
        carouselBody.classList.add('animate__fadeIn');
    }

    onIconClick(event) {

        const index = event.currentTarget.dataset.index;

        if (this.modal !== false){

            this.modal.iziModal('open');
            this.swiper.slideToLoop(index);
            this.playSliderVideo(index);

        }else{

            event.preventDefault();

            const modalHtml = this.renderSlider(this);

            $(`#${this.modalID} .presentation-modal`).html(modalHtml);

            this.enableLoading();

            this.initOpenModal(index);// Do something that might trigger an error

            //return ;

            $(this.modalSelectot+" .mySwiper123").css({
                'max-height':this.videoHeight+'px'
            });



            this.initSlider(this.initPlyr,index);

            this.swiper.slideToLoop(index);

            //console.log('we are sliding to ',index);
            this.swiper.slideToLoop(index);
            this.playSliderVideo(index);

            //this.sliderVideos[index].play();
            this.modal.on('open',  (e)=>{
               //console.log('Modal is open');
            });

            this.modal.on('closed',  (e)=>{
               //console.log('Modal is closed');
                this.pauseAll();
                // $(`#${this.modalID}`).remove();
                // this.modal = false;
                // this.swiper = false;
            });


        }
    }

    initPlyr(video) {

        const player = new Plyr(video, {
            controls: ['play-large', 'mute', 'fullscreen'],
        });
        const progressBars = document.querySelectorAll('.custom-progress-bar');

        progressBars.forEach(progressBar => {
            video.addEventListener('timeupdate', () => {
                if (video.duration) {
                    const percent = (video.currentTime / video.duration) * 100;
                    progressBar.value = percent;
                }
            });
        });

        return player;
    }

    initSlider = (initPlyrFn, index) => {

        this.sliderVideos = document.querySelectorAll('#'+this.modalID+' video');
       //console.log('initSlider',this.sliderVideos);

        const config = this.getSwiperConfig(index);
       //console.log('getSwiperConfig',"#"+this.modalID+" .mySwiper123", config);
        this.swiper = new Swiper("#"+this.modalID+" .mySwiper123", config);
        window.tis= this;
        $("#"+this.modalID+" .mySwiper123").css({
            'max-height':this.videoHeight+'px'
        });
        $("#"+this.modalID+" .mySwiper123").css({
            'height':this.videoHeight+'px'
        });
        $("#"+this.modalID+" .mySwiper123").css({
            'width':this.videoWidth+'px'
        });
        this.sliderPlayers = [];
        this.sliderVideos.forEach(video => {

            video.addEventListener("loadeddata", (event) => {
               //console.log('loaded data');
                this.disableLoading();
            });

            const player = initPlyrFn(video);
            this.sliderPlayers.push(player);
            // player.on('volumechange', (event) => {
            //    //console.log('changing  volume is ',event.detail.plyr.volume)
            //     this.setVolumeAll(!event.detail.plyr.muted ? event.detail.plyr.volume : 0)
            // });

            // Dodaj funkcjonalność play/pause na kliknięcie dla każdego wideo

            const slide = video.closest('.plyr__video-wrapper');
            slide.addEventListener('click', (e) => {
                if (this.sliderPlayingState){
                    this.pauseAll();//dfd
                }

            });

        });


        if (document.querySelector('#'+this.modalID+' .swiper-button-next') && document.querySelectorAll('#'+this.modalID+' .swiper-slide').length < 2)  {

            document.querySelector('#'+this.modalID+' .swiper-button-next').style.visibility = 'hidden'
            document.querySelector('#'+this.modalID+' .swiper-button-prev').style.visibility = 'hidden'

        }



    }

    renderSlider() {
        let templateString = $(this.element).find('#modal-slider-template').html();

        const renderFunction = this.handlbars.compile(templateString);//

        let data = {
            iframe_url: this.getBackendUrl(),
            reviews: this.getMetafields()
        }
       //console.log(';templateString',templateString, data)
        return renderFunction(data);
    }

    init() {


        //console.log('[itb_reviews_presentation] handlebars',Handlebars);
        this.handlbars.registerHelper('eq', function (v1, v2) {
            return v1 === v2;
        });

        let $ = itb.$;



        //console.log'init ready ');

    }

    initOpenModal() {
        const jQuery = this.itb.$;
        let config = this.getModalConfig(jQuery(`#${this.modalID}`));
        this.modal = jQuery(`#${this.modalID}`).iziModal(config);
        this.modal.iziModal('open');
       //console.log('sdfgdsgdfgs`#${this.modalID}` '+`#${this.modalID}`, config);


    }

    playIconsVideo() {
        let videoIndex = 0
        this.videos = document.querySelectorAll(`#${this.id} .video-container .video-icon`);
        this.delay(1000).then(() => this.playVideo(this.currentVideoIndex));
    }

    playVideo(videoIndex) {
        //console.log('[list_presentation_controller][playVideo] this.videos ',this.videos);
        if (videoIndex < this.videos.length) {
            const video = this.videos[videoIndex];
            // video.muted = true;
            video.play();

            // When the current video ends, play the next one
            video.onended = () => {
                this.currentVideoIndex++;
                if (this.currentVideoIndex >= this.videos.length) {
                    this.currentVideoIndex = 0;
                }
                this.delay(1000).then(() => this.playVideo(this.currentVideoIndex));
            };
        }
    }

    delay(sek) {
        return new Promise(resolve => setTimeout(() => resolve(), sek))
    }

    getReviews() {

        let reviews= [
                {
                    "link": "\/api\/reviews\/saltworks\/one\/fdgsdgsdfg",
                    "id": 82708,
                    "uniq": "fdgsdgsdfg",
                    "logo_path": "\/media\/cache\/resolve\/small\/upload\/review\/2024\/09\/11\/66e1c5a441191789890523.webp",
                    "media": [
                        {
                            "cached_url": "\/upload\/review\/2024\/09\/11\/66e1c5a40a210300432413.mp4",
                            "type": "video"
                        },
                        {
                            "thumb_url": "\/media\/cache\/resolve\/small\/upload\/review\/2024\/09\/11\/66e1c5a441191789890523.webp",
                            "type": "img"
                        },
                        {
                            "cached_url": "\/upload\/review\/2024\/09\/11\/66e1c5ac04726681758484.mp4",
                            "type": "presentation-video"
                        }
                    ],
                    "review": "fdgsdgd",
                    "nickname": "asadsf",
                    "verified_purchase": false,
                    "type": "presentation",
                    "created_at": "2024-09-11T17:30:17+01:00"
                },
                {
                    "link": "\/api\/reviews\/saltworks\/one\/sdfsdg",
                    "id": 82707,
                    "uniq": "sdfsdg",
                    "logo_path": "\/media\/cache\/resolve\/small\/upload\/review\/2024\/09\/11\/66e1c58a41546741102383.webp",
                    "media": [
                        {
                            "cached_url": "\/upload\/review\/2024\/09\/11\/66e1c589e5676648210072.mp4",
                            "type": "video"
                        },
                        {
                            "thumb_url": "\/media\/cache\/resolve\/small\/upload\/review\/2024\/09\/11\/66e1c58a41546741102383.webp",
                            "type": "img"
                        },
                        {
                            "cached_url": "\/upload\/review\/2024\/09\/11\/66e1c593b5a8f314499298.mp4",
                            "type": "presentation-video"
                        }
                    ],
                    "review": "sfdgfdsg",
                    "nickname": "sfdfsf",
                    "verified_purchase": false,
                    "type": "presentation",
                    "created_at": "2024-09-11T17:29:45+01:00"
                },
                {
                    "link": "\/api\/reviews\/saltworks\/one\/test",
                    "id": 82706,
                    "uniq": "test",
                    "logo_path": "\/media\/cache\/resolve\/small\/upload\/review\/2024\/09\/11\/66e1c4f6073d0343362497.webp",
                    "media": [
                        {
                            "cached_url": "\/upload\/review\/2024\/09\/11\/66e1c4f5c7fe9986887835.mp4",
                            "type": "video"
                        },
                        {
                            "thumb_url": "\/media\/cache\/resolve\/small\/upload\/review\/2024\/09\/11\/66e1c4f6073d0343362497.webp",
                            "type": "img"
                        },
                        {
                            "cached_url": "\/upload\/review\/2024\/09\/11\/66e1c4fc8f9c4562927060.mp4",
                            "type": "presentation-video"
                        }
                    ],
                    "review": "test",
                    "nickname": "test",
                    "verified_purchase": false,
                    "type": "presentation",
                    "created_at": "2024-09-11T17:27:19+01:00"
                }
            ]
        ;


        return reviews;
    }

    getSwiperConfig(index) {
        return {
            modules: [Navigation, Pagination, Mousewheel],
            direction:'vertical',
            //direction: "horizontal",
            //spaceBetween: 30,
            effect: "creative",
            //lazy: true,
            loop: true,
            spaceBetween: 10,
            initialSlide: index,
            height: this.videoHeight,
            width:this.videoWidth,
            slidesPerView: 1,
            mousewheel: {
                invert: false, // False means natural scroll direction
            },
            navigation: {
                nextEl: "#"+this.modalID+" .sbn",
                prevEl: "#"+this.modalID+" .sbp",
            },
            pagination: {
                el: "#"+this.modalID+" .swiper-pagination",
                clickable: true,
            },
            on: {
                transitionEnd: () => {
                   //console.log('slideChangeTransitionEnd document.querySelector(\'#'+this.modalID+' .swiper-slide-active\')')
                    let index =  document.querySelector('#'+this.modalID+' .swiper-slide-active').getAttribute('data-swiper-slide-index') ?? this.swiper.activeIndex;
                    if (this.swiper && index){
                       //console.log(this.LOG_SIGNATURE,'exec index on this.playSliderVideo(this.swiper.realIndex) index',this.swiper.realIndex, this.swiper.activeIndex, this.swiper, index)
                        this.playSliderVideo(index);
                    }

                },
                transitionStart:  () => {
                    if(this.swiper){
                       //console.log('pause on slideChangeTransitionStart');
                        this.pauseAll();
                    }
                },
            }


        };
    }

    getModalConfig(element) {
       //console.log('[link_controller] $element', element);
        return {
            id: this.modalID,
            title:  '',
            subtitle:  '',
            width: this.videoWidth,
            //iframe:true,
            //iframeHeight: element.attr('data-modal-height') || '500px',
            //iframeURL: window.location.origin+element.attr('data-modal-iframeurl'),
            //transitionIn: 'fadeInLeft',
            // transitionOut: 'fadeOutRight',
            //theme: element.attr('data-modal-theme') || '',
            // navigateCaption: true,
            //navigateArrows: 'closeScreenEdge',
            //arrowKeys: true,
            // onOpening: this.beforeOpen.bind(this),
        };
    }

    changeSlide() {

    }

    pauseAll() {
        this.sliderPlayingState = false;
        this.sliderVideos.forEach(player => {
            player.pause();
             //console.log('player is ',player);
            //player.currentTime = 0;
        });
    }

    setVolumeAll() {
        this.sliderPlayers.forEach(player => {
            player.muted = false;
            player.volume = 1;


            player.on('volumechange', (event) => {
                const isMuted = event.detail.plyr.muted;
               //console.log('Muted:', isMuted);

                if (isMuted) {

                    player.muted = true;
                } else {

                    player.muted = false;
                    player.volume = 1;
                }
            });
        });
    }


    playSliderVideo(index) {
        if(!this.sliderVideos){
            console.warn('playSliderVideo empty');
            return;
        }
        this.sliderPlayingState = true;
       //console.log(this.LOG_SIGNATURE,' playing index on a this.sliderVideos',index,this.sliderVideos )
        this.sliderVideos[index].play();

        /*
                if (this.sliderPlayingState === false){
                    if (index !== false && this.weDontNeedScroll(index)){
                       //console.log(this.LOG_SIGNATURE,'this.weDontNeedScroll(index)',this.weDontNeedScroll(index));
                       //console.log('index play this.sliderPlayingState',index, this.sliderPlayingState);
                        this.sliderPlayingState = true;
                        this.sliderVideos[index].play();
                        return;
                    }
                    let activeSlide = document.querySelector('#'+this.modalID+' .swiper-slide-active video');
                   //console.log('slide play this.sliderPlayingState', activeSlide, this.sliderPlayingState);
                    this.sliderPlayingState = true;
                    activeSlide.play();
                }*/
    }

    enableLoading(){
       itb.effects.loading(`#${this.modalID} .presentation-modal`);
       document.querySelector(`#${this.modalID} .swiper`).style.opacity = 0;
    }

    disableLoading(){
        itb.effects.removeLoading(`#${this.modalID} .presentation-modal`);
        document.querySelector(`#${this.modalID} .swiper`).style.opacity = 1;
    }

    weDontNeedScroll(index) {
        return index == this.swiper.realIndex
    }

    getMetafields(){
       //console.log(this.LOG_SIGNATURE,' getMetafields() this.reviewsValue ',this.reviewsValue);
        return this.reviewsValue;
    }

    getBackendUrl(){
        return window.itbReviewsBackendUrl;
    }

}
