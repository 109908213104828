//this class is responsible for sending size messages from a frame to parent  block


export default class IframeCloser {
  iframeId

  constructor() {
    // Pobierz bieżący URL z przeglądarki
    const parts = window.location.pathname.split('/').filter(Boolean);
    if (typeof parts[4] === 'undefined') {
      this.iframeId = false
    }
    this.iframeId = parts[4];
  }

  closeIframe() {
    //generate event
    var elementId = this.iframeId;
    let data = {
      status: 'ok',
      actions: [{
        type: 'script',
        content: `$('#${elementId}').iziModal('close')`,
      }]
    };

    window.parent.postMessage({
      action: 'accessItb',
      data: data
    }, '*');

  }
}
